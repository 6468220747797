<template>
  <section class="listing--partner-header">
    <div class="left--part">
      <client-only>
        <vue-gallery-slideshow
          :images="galleries"
          :index="imageIndex"
          @close="closeGallery()"
        ></vue-gallery-slideshow>
      </client-only>
      <v-tabs>
        <v-tab :href="`#tab-1`" v-if="displayPhotos && displayPhotos.length > 0">{{
          $t('general.gallery')
        }}</v-tab>
        <v-tab :href="`#tab-2`" v-if="description">{{ $t('general.description') }}</v-tab>
        <v-tab :href="`#tab-3`" v-if="panoEmbedUrl">{{ $t('general.panorama') }}</v-tab>
        <v-tab :href="`#tab-4`" v-if="sketch && sketch.length > 0">{{
          $t('general.floorplan')
        }}</v-tab>
        <v-tab-item
          :value="'tab-1'"
          v-if="displayPhotos && displayPhotos.length > 0"
          @click="clickDisplayPhoto(0)"
        >
          <img class="display--photos" :src="displayPhotos[0]" />
          <div class="btn btn-primary--outline open--gallery">
            <i class="ion-images"></i>
            <span
              >{{ $t('general.view1') }} {{ displayPhotos.length }} {{ $t('general.view2') }}</span
            >
          </div>
        </v-tab-item>
        <v-tab-item :value="'tab-2'" v-if="description">
          <div class="pa-2" v-html="description"></div>
        </v-tab-item>
        <v-tab-item :value="'tab-3'" v-if="panoEmbedUrl">
          <iframe
            class="pano"
            :src="panoEmbedUrl"
            allowfullscreen="allowfullscreen"
            loading="lazy"
          ></iframe>
        </v-tab-item>
        <v-tab-item :value="'tab-4'" v-if="sketch && sketch.length > 0" @click="clickSketch(0)">
          <img class="display--photos" :src="sketch[0]" />
          <div class="btn btn-primary--outline open--gallery">
            <i class="ion-images"></i>
            <span>{{ $t('general.view1') }} {{ sketch.length }} {{ $t('general.view2') }}</span>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
    <div class="right--part">
      <img class="profile--picture" :src="profilePictureUrl" />
      <div class="name">
        {{ name }}
      </div>
      <!--      <div class="desc" v-if="description" v-html="description"></div>-->
      <div class="address">
        <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />
        <span v-if="address2">{{ `${address1} - ${address2}` }}</span>
        <span v-else>{{ address1 }}</span>
      </div>
    </div>
  </section>
</template>

<script>
const VueGallerySlideshow = () => import('vue-gallery-slideshow');
import { mapState } from 'vuex';

export default {
  name: 'search-profile',
  components: {
    VueGallerySlideshow,
  },
  data: () => ({
    galleries: [],
  }),
  computed: {
    ...mapState({
      profilePictureUrl: (state) => state.v2.listingPartner.profilePictureUrl,
      name: (state) => state.v2.listingPartner.name,
      address1: (state) => state.v2.listingPartner.addressLine1,
      address2: (state) => state.v2.listingPartner.addressLine2,
      description: (state) => state.v2.listingPartner.description,
      panoEmbedUrl: (state) => state.v2.listingPartner.panoEmbedUrl,
      displayPhotos: (state) => state.v2.listingPartner.displayPhotos,
      sketch: (state) => state.v2.listingPartner.sketch,
      imageIndex: (state) => state.v2.listing.imageIndex,
    }),
  },
  methods: {
    clickDisplayPhoto(index) {
      if (index < this.displayPhotos.length) {
        this.galleries = this.displayPhotos;
        this.$store.commit('v2/listing/set_image_index', index);
      }
    },
    clickSketch(index) {
      if (index < this.sketch.length) {
        this.galleries = this.sketch;
        this.$store.commit('v2/listing/set_image_index', index);
      }
    },
    closeGallery() {
      this.$store.commit('v2/listing/set_image_index', null);
    },
  },
};
</script>
